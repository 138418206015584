<template>
    <div>
      <h5 class="pageTitle" v-html="pageTitle"></h5>   
        
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th>사용자ID</th>
            <th>사용자명</th>
            <th>위도</th>
            <th>경도</th>
            <th>고도</th>
            <th>방향</th>
            <th>디바이스ID</th>
            <th>기록일시</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(drone, index) in paginatedData" :key="drone.id">
            <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
            <td>{{ drone.controlUserId }}</td>
            <td>{{ drone.name }}</td>
            <td>{{ drone.lat }}</td>
            <td>{{ drone.lng }}</td>
            <td>{{ drone.velocity }}</td>
            <td>{{ drone.direction }}</td>
            <td>{{ drone.deviceId }}</td>
            <td>{{ drone.createdAt }}</td>
            <!-- <td>{{ new Date(drone.createdAt).toLocaleString('ko-KR') }}</td> -->
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        
        <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">이전</button>
        <span>{{ currentPage }} / {{ totalPages }}</span>
        <button class="pagination-button" @click="nextPage" :disabled="currentPage === totalPages">다음</button>        
        <button class='llist' @click="goBack">목록</button> 
      </div>
    </div>
  </template>
  
  <script>
import axios from '@/axios';
import { getDateTimeSecs, toTimestamp } from '../../lib/prettyDate';
  
  export default {
    props: ['startdate','enddate','deviceId','isFlying'],
    data() {
      return {
        pageTitle:'드론 목록 상세',
        drones: [],
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,        
      };
    },
    computed: {
      totalPages() {
        return Math.ceil(this.totalItems / this.itemsPerPage);
      },
      paginatedData() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.drones.slice(start, end);
      }
    },
    methods: {
      fetchData() {
        const url = `/locations/DroneDetail?startdate=${this.startdate}&enddate=${this.enddate}&deviceId=${this.deviceId}`;
        //console.log(url);    
        //console.log("=======>isFlying:"+this.isFlying);    
        axios.get(url, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token.access_token}`
          }
        })
        .then(response => {
          this.drones = response.data;
          
          this.drones.forEach(item => {
            // 날짜를 타임스탬프로 변환
            item.createdAtTimestamp = toTimestamp(new Date(item.createdAt));
            // 사람이 읽을 수 있는 날짜 형식으로 변환
            item.createdAt = getDateTimeSecs(new Date(item.createdAt));
          });
          
          // 타임스탬프를 기준으로 내림차순 정렬
          this.drones.sort((a, b) => b.createdAtTimestamp - a.createdAtTimestamp);
          this.totalItems = this.drones.length;
          if (this.isFlying) {
            this.checkAndUpdateDroneState();
          }
          
        })
        .catch(error => {
          console.error(error);
        });
      },

      checkAndUpdateDroneState() {
        
        // 현재 비행 중인 드론의 마지막 로그 시간 가져오기
        const lastLogTime = new Date(this.drones[this.drones.length - 1].createdAt); // 예시에서는 createdAt을 사용
        const currentTime = new Date();
        const timeDiff = (currentTime - lastLogTime) / 1000 / 60; // 분 단위 차이 계산

        //console.log("lastlogTime:"+lastLogTime);
        if (timeDiff > 3) {
          // 드론 상태 업데이트 요청
          this.updateDroneState(lastLogTime);       
        }
      },
      
      updateDroneState(lastLogTime) {
              
        const url = `/locations/DroneStateUpdate?date=${lastLogTime.toISOString()}&deviceId=${this.deviceId}`;
        //console.log(url);
        axios.put(url, {}, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token.access_token}`
          }
        })
        .then(response => {
          console.log("Update Data:" + response.data);
        })
        .catch(error => {
          console.error(error);
        });
      },

      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      goBack() {
      this.$router.go(-1); // 이전 페이지로 이동
    }
    },
    mounted() {
      this.fetchData();
    }
  };
  </script>
  
  <style scoped>
  
  .pageTitle {
    padding: 15px;
    font-weight: bold;
    margin-bottom: 50px;
  }

  table {
    
    width: 80%;
    height: 50px;
    border-collapse: collapse;
    margin: 0 auto;
    text-align:center;
  }
  
  th, td {
    border: 1px solid #cbc8c8;
    padding: 8px; 
    text-align:center;
    height: 50px;
  }
  
  th {
    background-color: #e3e4eb;
    text-align: center;
    height: 55px;  
    font-weight: bold;   
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #4CAF50; /* 배경색 */
  color: rgb(251, 250, 251); /* 글자색 */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
}

  .pagination-button:hover {
    background-color: #307733; /* 호버 시 배경색 변화 */
  }

  .pagination-button:disabled {
    background-color: #cccccc; /* 비활성화 시 배경색 */
    color: #666666; /* 비활성화 시 글자색 */
    cursor: not-allowed;
  }
  
  .llist {
    margin-left: 100px;
    background-color: #c83ad5;
    color: white;
    padding: 5px 10px;
    text-decoration: none;
    border: none;
    cursor: pointer;   
    /* margin-right: 120px !important; 오른쪽 마진을 강제 적용 */
  }

  </style>
  